var render = function render(){var _vm=this,_c=_vm._self._c;return _c('MainLayout',{staticClass:"list_radius-all",scopedSlots:_vm._u([{key:"navbar-title",fn:function(){return [_vm._v(_vm._s(_vm.fullName ? _vm.fullName : "")+" ")]},proxy:true}])},[_c('div',{staticClass:"list__content-adding"},[_c('div',{staticClass:"title card"},[(
          this.$store.getters.getCurrentRole == 70 ||
            this.$store.getters.getCurrentRole == 60 ||
            this.$store.getters.getCurrentRole == 50 ||
            this.$store.getters.getCurrentRole == 30 ||
            this.$store.getters.getCurrentRole == 31
        )?_c('div',{staticClass:"title__avatar title__avatar_grey title__avatar-admin"}):_c('div',{staticClass:"title__avatar title__avatar_grey title__avatar-user"}),_c('p',{staticClass:"title__text"},[_vm._v(_vm._s(_vm.currentRole))]),_c('router-link',{staticClass:"btn btn_color_grey btn-edit",attrs:{"to":"/profile/edit"}},[_vm._v(" Редактировать ")])],1)]),_c('div',{staticClass:"keep keep-user"},[(_vm.sourceObject)?_c('BasicTableWithoutHeader',{attrs:{"table-data":_vm.getDataRequisites},scopedSlots:_vm._u([{key:"item.4.value",fn:function({item}){return [_c('p',{style:(item ? 'color: #95C23D !important' : 'color: #EB5C6D !important')},[_vm._v(" "+_vm._s(item.value)+" ")])]}},{key:"item.6.value",fn:function({}){return [_c('div',{staticClass:"d-flex flex-row align-center"},[_c('v-btn',{staticClass:"btn_color_green btn",attrs:{"disabled":!_vm.sourceObject.marked_to_clean_at,"type":"submit"},on:{"click":_vm.restoreFunction}},[_vm._v(" Отменить удаление ")]),_c('p',{staticStyle:{"color":"#EB5C6D","padding-left":"15px"}},[_vm._v(" Дата удаления aккаунта: "+_vm._s(_vm.deleteAccountDate)+" ")])],1)]}}],null,false,683754881)}):_vm._e()],1),(
      this.$store.getters.getCurrentRole === 70 ||
        this.$store.getters.getCurrentRole === 60 ||
        this.$store.getters.getCurrentRole === 30 ||
        this.$store.getters.getCurrentRole === 31
    )?_c('div',[_c('div',{staticClass:"list__content-adding"},[_c('div',{staticClass:"title card"},[_c('div',{staticClass:"title__avatar title__avatar_grey title__avatar-docs"}),_c('p',{staticClass:"title__text"},[_vm._v("Реестры")])])]),(_vm.dessert)?_c('v-data-table',{staticClass:"ym-hide-content",attrs:{"headers":_vm.headers,"items":_vm.desserts,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.status",fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-space-between",staticStyle:{"max-width":"130px"}},[_vm._v(" "+_vm._s(item.status)+" "),_c('v-icon',{staticClass:"mr-2",attrs:{"dense":"","color":"gray"},on:{"click":function($event){return _vm.download(item)}}},[_vm._v(" mdi-tray-arrow-down ")])],1)]}}],null,false,2787114252)}):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }