<template>
  <MainLayout class="list_radius-all">
    <template v-slot:navbar-title>{{ fullName ? fullName : "" }} </template>

    <div class="list__content-adding">
      <div class="title card">
        <div
          v-if="
            this.$store.getters.getCurrentRole == 70 ||
              this.$store.getters.getCurrentRole == 60 ||
              this.$store.getters.getCurrentRole == 50 ||
              this.$store.getters.getCurrentRole == 30 ||
              this.$store.getters.getCurrentRole == 31
          "
          class="title__avatar title__avatar_grey title__avatar-admin"
        ></div>

        <div
          v-else
          class="title__avatar title__avatar_grey title__avatar-user"
        ></div>
        <p class="title__text">{{ currentRole }}</p>

        <router-link to="/profile/edit" class="btn btn_color_grey btn-edit">
          Редактировать
        </router-link>
      </div>
    </div>

    <div class="keep keep-user">
      <BasicTableWithoutHeader
        v-if="sourceObject"
        :table-data="getDataRequisites"
      >
        <template #item.4.value="{item}">
          <p
            :style="
              item ? 'color: #95C23D !important' : 'color: #EB5C6D !important'
            "
          >
            {{ item.value }}
          </p>
        </template>
        <template #item.6.value="{}">
          <div class="d-flex flex-row align-center ">
            <v-btn
              :disabled="!sourceObject.marked_to_clean_at"
              type="submit"
              @click="restoreFunction"
              class=" btn_color_green btn"
            >
              Отменить удаление
            </v-btn>
            <p style=" color: #EB5C6D; padding-left:15px;">
              Дата удаления aккаунта: {{ deleteAccountDate }}
            </p>
          </div>
        </template>
      </BasicTableWithoutHeader>
    </div>
    <div
      v-if="
        this.$store.getters.getCurrentRole === 70 ||
          this.$store.getters.getCurrentRole === 60 ||
          this.$store.getters.getCurrentRole === 30 ||
          this.$store.getters.getCurrentRole === 31
      "
    >
      <div class="list__content-adding">
        <div class="title card">
          <div
            class="title__avatar title__avatar_grey title__avatar-docs"
          ></div>
          <p class="title__text">Реестры</p>
        </div>
      </div>

      <v-data-table
        v-if="dessert"
        :headers="headers"
        :items="desserts"
        hide-default-footer
        class="ym-hide-content"
      >
        <template v-slot:item.status="{ item }">
          <div style="max-width: 130px" class="d-flex justify-space-between">
            {{ item.status }}
            <v-icon dense color="gray" class="mr-2" @click="download(item)">
              mdi-tray-arrow-down
            </v-icon>
          </div>
        </template>
      </v-data-table>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import BasicTableWithoutHeader from "@/components/tables/BasicTableWithoutHeader";
import { dictionariesHelper } from "@/helpers/dictionariesHelper";
import moment from "moment";
import tableColsPassageLog from "@/helpers/tableCols/passageLog";
import phoneFilter from "@/helpers/filters/phoneFilter";

import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";

export default {
  name: "ProfilePage",
  components: {
    BasicTableWithoutHeader,
    MainLayout
  },
  data: () => ({
    code: null,
    codeExp: false,
    timer: null,
    headers: [
      { text: "Название", value: "name", sortable: false },
      { text: "Статус", value: "status", sortable: false },
      { value: "actions", sortable: false },

      { text: "Тип объекта", value: "objectsType", sortable: false },
      { text: "Название объекта", value: "objectsName", sortable: false },
      { text: "Дата выгрузки", value: "start_date", sortable: false }
    ]
  }),
  computed: {
    header() {
      return tableColsPassageLog;
    },
    fullName() {
      return this.$store.getters.getFullName;
    },
    currentUser() {
      return this.$store.getters.getCurrentUser;
    },
    dessert() {
      return this.$store.getters.getDesserts;
    },
    sourceObject() {
      return this.$store.getters.getUserProfile;
    },
    desserts() {
      if (this.dessert.results) {
        return Array.from(this.dessert.results).map(el => ({
          id: el.id,
          // objectsType: el.object.type,
          objectsType:
            el.object != null
              ? dictionariesHelper.objectType[el.object.type]
              : "-",
          objectsName: el.object != null ? el.object.name : "-",
          name: el.type_translate,
          start_date: moment(el.created_at * 1000).format(
            "DD.MM.YYYY HH:mm:ss"
          ),
          end_date: moment(el.edited_at * 1000).format("DD.MM.YYYY HH:mm:ss"),
          status:
            el.status == "finished"
              ? "Готов"
              : el.status == "error"
              ? "Ошибка"
              : "Формируется..."
        }));
      } else return [];
    },
    getDataRequisites() {
      const requisites = [
        {
          text: "ФИО",
          value: this.fullName
        },
        {
          text: "Телефон",
          value: phoneFilter.execute(this?.sourceObject?.phone_number)
        },
        {
          text: "E-mail",
          value: this.sourceObject.email ? this.sourceObject.email : "-"
        },
        {
          text: "Статус",
          value:
            dictionariesHelper.accountsStatus.userStatus[
              this.sourceObject.is_active
            ]
        },
        {
          text: "Регистрация",
          value:
            dictionariesHelper.accountsStatus.userAuth[
              this.sourceObject.is_phone_confirmed
            ]
        },
        {
          text: "ID",
          value: this.sourceObject.id
        }
      ];

      if (this.sourceObject.marked_to_clean_at) {
        requisites.push({
          text: "Отмена удаления",
          value: this.sourceObject.marked_to_clean_at
        });
      }

      return requisites;
    },
    currentRole() {
      return this.$store.getters.getCurrentRoleName;
    },
    temp_token() {
      return this.$store.getters.getTempToken;
    },
    deleteAccountDate() {
      return moment(this.sourceObject.marked_to_clean_at * 1000).format(
        "DD.MM.YYYY HH:mm:ss"
      );
    }
  },
  created() {
    this.isDateLoaded = true;
    this.$store.dispatch("fetchUserProfile").finally(() => {
      this.isDateLoaded = false;
    });
  },
  methods: {
    startTimer() {
      const time = 3 * 60 * 1000;
      const startDate = Date.now();
      const endDate = startDate + time;
      const res = setInterval(() => {
        const remains = endDate - Date.now();

        if (remains <= 1000) {
          clearInterval(res);
        }
        this.codeExp = moment(remains).format("mm:ss");
        if (this.codeExp == "00:00") {
          this.sendSMS = true;
          this.codeExp = false;
        }
      }, 1000);
    },

    download(item) {
      this.loadingBtn = true;
      this.$store.dispatch("downloadPassLogs", item).then(() => {
        this.loadingBtn = false;
      });
    },
    restoreFunction() {
      this.isDateLoaded = true;
      this.$store
        .dispatch("restoreProfile", { id: this.sourceObject.id })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.restore(successMessageDictionary.profile)
          );
        })
        .then(() => {
          this.$store.dispatch("fetchUserProfile");
        });
    }
  }
};
</script>

<style scoped></style>
